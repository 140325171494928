export const LOGIN = "/";
export const REGISTER = "/register"
export const HOME = "/home";
export const INVOICE = "/invoice";
export const INVOICE_VIEW = "/invoice/:slug_id";
export const REPORT = "/completed";
export const INV_APPROVAL = "/inv-approval";
export const INV_APPROVAL_VIEW = "/inv-approval/:slug_id";
export const INVOICE_CLAIM = "/invoice-claim/active";
export const SUCCESS = '/success'
export const CANCLE = '/cancle'
export const TEAM = '/team'
export const BILL_HISTORY = '/bill-history'
export const CARD = '/card'
export const STRIPE = '/stripe-payment'
export const NOTIFY = '/notification'
export const HISTORY = '/logs'
export const ACTIVE = '/invoice-claim/active'
export const PENDING_INFO = '/invoice-claim/pending_info'
export const DENIED = '/invoice-claim/denied'
export const FINALIZED = '/invoice-claim/finalized'
export const CLAIM_ACTIVE_VIEW = '/invoice-claim/active/:slug_id'
export const FEEDBACK = '/feedback'