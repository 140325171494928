import { UserClient } from "../client/UserClient"
import { HOME,INVOICE_CLAIM } from "../../services/UserRoutePath"
import { storeLoggedInUser, storeUserRole, storeUserToken } from "../../services/UserLocalStorage"
import { storeAdminRole, storeAdminToken, storeLoggedInAdmin } from "../../services/AdminLocalStorage"
import { ADMIN_DASHBOARD, ADMIN_INVOICE, ADMIN_JOB_TYPE, ADMIN_LOGIN, ADMIN_ORDERS, ADMIN_PAYMENT, ADMIN_TEAM, ADMIN_USER } from "../../services/AdminRoutePath"
import { toast } from 'react-toastify';
import axios from "axios"
import { USER_FEEDBACK_LIST } from "./AdminAction"
import { getLoggedInUser } from '../../services/UserLocalStorage';
const user = getLoggedInUser();
export const LOGIN = "LOGIN"
export const LOGOUT = "LOGOUT"

export const GET_INVOICE_LIST = "GET_INVOICE_LIST"
export const GET_APPROVAL_INVOICE_LIST = "GET_APPROVAL_INVOICE_LIST"
export const GET_INVOICE_NEED_LIST = "GET_INVOICE_NEED_LIST"
export const GET_SEARCH_LIST = "GET_SEARCH_LIST"
export const GET_SEARCH_LIST_ARRAY = "GET_SEARCH_LIST_ARRAY"
export const GET_PHOTO_LIST = "GET_PHOTO_LIST"
export const GET_DOCUMENT_LIST = "GET_DOCUMENT_LIST"
export const GET_MESSAGE_LIST = "GET_MESSAGE_LIST"
export const GET_JOB_TYPES = "GET_JOB_TYPES"
export const GET_STATUS = "GET_STATUS"
export const GET_STRIPE_PAYMENT = "GET_STRIPE_PAYMENT"
export const GET_INVOICE_BY_ID = "GET_INVOICE_BY_ID"
export const GET_CLAIM_BY_ID = "GET_CLAIM_BY_ID"
export const GET_APPROVAL_INVOICE_BY_ID = "GET_APPROVAL_INVOICE_BY_ID"
export const GET_REPORTS_LIST = "GET_REPORTS_LIST"
export const GET_USER_TEAM_LIST = "GET_USER_TEAM_LIST"
export const GET_USER_CARDLIST = "GET_USER_CARDLIST"
export const GET_USER_BILL_LIST = "GET_USER_BILL_LIST"
export const GET_PAY_AMOUNT = "GET_PAY_AMOUNT"
export const GET_USER_CARDS = "GET_USER_CARDS"
export const GET_NOTIFY_USER = "GET_NOTIFY_USER"
export const GET_HISTORY = "GET_HISTORY"
export const GET_HISTORY_LIST = "GET_HISTORY_LIST"
export const GET_PHOTOS = "GET_PHOTOS"
export const GET_DOCUMENTS = "GET_DOCUMENTS"

export const ADD_INVOICE_CLAIM = "ADD_INVOICE_CLAIM"
export const EDIT_INVOICE_CLAIM = "EDIT_INVOICE_CLAIM"
export const GET_INVOICE_CLAIM_LIST = "GET_INVOICE_CLAIM_LIST"
export const DELETE_INVOICE_CLAIM = "DELETE_INVOICE_CLAIM"
export const DELETE_CLAIM_INVOICPDF = "DELETE_CLAIM_INVOICPDF"
export const DELETE_CLAIM_PAPERWORKT = "DELETE_CLAIM_PAPERWORKT"
export const DELETE_CLAIM_WORKSHEET = "DELETE_CLAIM_WORKSHEET"
export const DELETE_CLAIM_PHOTO = "DELETE_CLAIM_PHOTO"
export const GET_CLAIM_MESSAGE_LIST = "GET_CLAIM_MESSAGE_LIST"
export const ADD_CLAIM_MESSAGE = "ADD_CLAIM_MESSAGE"
export const DELETE_CLAIM_MESSAGE = "DELETE_CLAIM_MESSAGE"

export const USER_FEEDBACK="USER_FEEDBACK"

export const ADD_INVOICE = "ADD_INVOICE"
export const ADD_MESSAGE = "ADD_MESSAGE"
export const UPLOAD_PHOTO = "UPLOAD_PHOTO"
export const UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT"
export const ADD_USER_TEAM = "ADD_USER_TEAM"
export const UPDATE_CARD_STATUS = "UPDATE_CARD_STATUS"
export const ADD_USER_CARD = "ADD_USER_CARD"
export const ADD_NOTIFY_USER = "ADD_NOTIFY_USER"

export const EDIT_USER_TEAM = "EDIT_USER_TEAM"
export const EDIT_USER_CARD = "EDIT_USER_CARD"
export const EDIT_INVOICE = "EDIT_INVOICE"
export const EDIT_NOTIFY_USER = "EDIT_NOTIFY_USER"
export const NOTIFICATION_LIST = "NOTIFICATION_LIST"


export const DELETE_PHOTO = "DELETE_PHOTO"
export const DELETE_SHEET = "DELETE_SHEET"
export const DELETE_INVOICE = "DELETE_INVOICE"
export const DELETE_TEAM = "DELETE_TEAM"
export const DELETE_CARD = "DELETE_CARD"
export const DELETE_HISTORY = "DELETE_HISTORY"
export const DELETE_HISTORY_ALL = "DELETE_HISTORY_ALL"


export const login = (obj, setLoading, navigate, setError) => (dispatch) => {
  UserClient.post('/login', obj).then((response) => {
    // console.log(response.data.data, '--------------login-----------------------')
    dispatch({
      type: LOGIN,
      payload: response.data.data
    })
    setLoading(false);
    if (response.data.data.role == 'admin') {
      storeAdminToken(response.data.access_token)
      storeAdminRole(`${response.data.data.role}`)
      storeLoggedInAdmin(response.data.data)
      if(response.data.data.status?.includes(1) && response.data.access_token) return navigate(ADMIN_DASHBOARD)
      else if(response.data.data.status?.includes(2) && response.data.access_token) return navigate(ADMIN_INVOICE)
      else if(response.data.data.status?.includes(3) && response.data.access_token) return navigate(ADMIN_ORDERS)
      else if(response.data.data.status?.includes(4) && response.data.access_token) return navigate(ADMIN_USER)
      else if(response.data.data.status?.includes(5) && response.data.access_token) return navigate(ADMIN_TEAM)
      else if(response.data.data.status?.includes(6) && response.data.access_token) return navigate(ADMIN_PAYMENT)
      else if(response.data.data.status?.includes(7) && response.data.access_token) return navigate(ADMIN_JOB_TYPE)
      else if(!response.data.data && !response.data.access_token) return navigate(ADMIN_LOGIN)
    } else {
      storeUserToken(response.data.access_token);
      storeUserRole(`${response.data.data.role}`);
      storeLoggedInUser(response.data.data);
      if(response.data?.data?.access_page == 'invoices'){
        navigate(HOME)
      }else if(response.data?.data?.access_page == 'collections'){
        navigate(INVOICE_CLAIM)
      }else{
        navigate(HOME)
      }
    }

  }).catch((error) => {
    // console.log(error)
    setError(error.response.data.message)
    setLoading(false);
  })
}
export const logout = (navigate, setLoading) => (dispatch) => {
  UserClient.post('/logout').then((response) => {
    dispatch({
      type: LOGOUT,
      payload: response
    })
    setTimeout(() => {
      setLoading(false);
      localStorage.clear();
      // navigate("/", { state: { statePath: "/user" } })
      window.location.href = "/";
    }, 500);


  }).catch((error) => {
    console.log(error)
    setLoading(false);
  })
}

export const getDataList = (url, type, setLoading) => (dispatch) => {
  UserClient.get(url).then((res) => {
    dispatch({
      type: type,
      payload: res.data.data,
      path: res.data.path,
      totalItemCount: res.data.totalItemCount,
    })
    setLoading(false);
  }).catch((error) => { setLoading(false) })
}
export const getData = (obj, url, type) => (dispatch) => {
  UserClient.post(url, obj).then((res) => {
    dispatch({
      type: type,
      payload: res.data.data,
    })
  }).catch((error) => {
    console.log(error)
  })
}
export const updatePostData = (obj, url, type,setLoading) => (dispatch) => {
  UserClient.post(url, obj).then((res) => {
    dispatch({
      type: type,
      payload: res.data.data,
    })
    setLoading(false);
  }).catch((error) => {
    setLoading(false);
    console.log(error)
  })
}
export const getHistoryData = (url, type,setLoading) => (dispatch) => {
  UserClient.get(url).then((res) => {
    dispatch({
      type: type,
      payload: res.data.data,
    })
    setLoading(false)
  }).catch((error) => {
    console.log(error)
  })
}
export const getDataSearch = (obj,url, type, setLoading) => (dispatch) => {
  UserClient.post(url,obj).then((res) => {
    dispatch({
      type: type,
      payload: res.data.data,
    })
    setLoading(false)
  }).catch((error) => { console.log(error); 
    setLoading(false) 
  })
}
export const getDataWithLoading = (url, type, setLoading) => (dispatch) => {
  UserClient.get(url).then((res) => {
    dispatch({
      type: type,
      payload: res.data.data,
    })
    setLoading(false)
  }).catch((error) => { console.log(error); setLoading(false) })
}
export const curdData = (obj, setLoading, setShow, setAction, url, type, getUrl, getType, msg) => (dispatch) => {
  UserClient.post(url, obj).then((response) => {
    dispatch({
      type: type,
      payload: response
    })
    setLoading(false); setShow(false); setAction(false);
    dispatch(getDataList(getUrl, getType, setLoading)); toast.success(msg);
  }).catch((error) => {
    setLoading(false); setShow(false); setAction(false);
    dispatch(getDataList(getUrl, getType, setLoading)); toast.error(error.response.data.message);
  })
}
export const getMethodCallFun = (url, setLoading) => () => {
  UserClient.get(url).then((res) => {   
    setLoading(false)
  }).catch((error) => {
    setLoading(false) 
    console.log(error)
  })
}
export const getStripePayment = (obj, setLoading, navigate) => (dispatch) => {
  UserClient.post(`/user/add-stripe-payment`, obj).then((res) => {
    dispatch({
      type: GET_STRIPE_PAYMENT,
      payload: res.data.data,
    })
    setLoading(false);
    toast.success("Payment done successfully!");
    setTimeout(() => {
      navigate(HOME)
    }, 1000);
  }).catch((error) => { console.log(error); setLoading(false); toast.error(error.response.data.message); })
}

/* ---------------------------------------------------------card and billing history -----------------------------------------*/
export const addUserCard = (obj, id, setLoading, setShow, setAction) => (dispatch) => {
  UserClient.post('/user/add-card', obj).then((response) => {
    dispatch({
      type: ADD_USER_CARD,
      payload: response
    })
    setLoading(false); setShow(false); setAction('');
    dispatch(getDataList(`/user/get-user-card/${id}/?page=${1}`, GET_USER_CARDLIST, setLoading)); dispatch(getCards(id, setLoading)); toast.success("Card added successful.");
  }).catch((error) => {
    setLoading(false); setShow(false); setAction('');
    dispatch(getDataList(`/user/get-user-card/${id}/?page=${1}`, GET_USER_CARDLIST, setLoading)); dispatch(getCards(id, setLoading)); toast.error(error.response.data.message);
  })
}
export const getPaymentAmount = (id, setLoading) => (dispatch) => {
  UserClient.get(`/user/get-pay-amount/${id}`).then((res) => {
    dispatch({
      type: GET_PAY_AMOUNT,
      payload: res.data.data.Pay_amount,
    })
    setLoading(false)
  }).catch((error) => { console.log(error); setLoading(false) })
}
export const makeAsDefaultCard = (id, obj, user_id, setLoading, currentPage, setCurrentPage,checkId) => (dispatch) => {
  UserClient.post(`/user/make-card-default/${id}`, obj).then((response) => {
    dispatch({
      type: UPDATE_CARD_STATUS,
      payload: response
    })
    dispatch(getDataList(`/user/get-user-card/${user_id}/?page=${1}`, GET_USER_CARDLIST, setLoading)); setCurrentPage(currentPage); toast.success("Default card has been changed successfully.")
   setTimeout(()=>{document.getElementById(checkId).disabled = false;},1500) 
    setLoading(false)
  }).catch((error) => {
    setLoading(false)
    dispatch(getDataList(`/user/get-user-card/${user_id}/?page=${1}`, GET_USER_CARDLIST, setLoading)); setCurrentPage(currentPage); toast.error(error.response.data.message)
  })
}
export const getCards = (id, setLoading) => (dispatch) => {
  UserClient.get(`/user/get-user-cards/${id}`).then((res) => {
    dispatch({
      type: GET_USER_CARDS,
      payload: res.data.data,
    })
    setLoading(false)
  }).catch((error) => { console.log(error); setLoading(false) })
}
/*--------------------------------------------manage notification--------------------------*/
export const getNotifyUser = (setLoading, id) => (dispatch) => {
  UserClient.get(`/user/get-notify-user/${id}`).then((res) => {
    dispatch({
      type: GET_NOTIFY_USER,
      payload: res.data.data,
    })
    setLoading(false);
  }).catch((error) => { console.log(error); setLoading(false); })
}
export const addNotification = (obj, user_id, setLoading, setShow, setAction) => (dispatch) => {
  UserClient.post(`/user/add-notify`, obj).then((response) => {
    dispatch({
      type: ADD_NOTIFY_USER,
      payload: response
    })
    setLoading(false); setShow(false); setAction('');
    dispatch(getNotifyUser(setLoading, user_id)); toast.success("Email notification added successful.");
  }).catch((error) => {
    setLoading(false); setShow(false); setAction('');
    dispatch(getNotifyUser(setLoading, user_id)); toast.error(error.response.data.message);
  })
}
export const updateNotification = (id, obj, user_id, setLoading, setShow, setAction,) => (dispatch) => {
  UserClient.post(`/user/update-notify/${id}`, obj).then((response) => {
    dispatch({
      type: EDIT_NOTIFY_USER,
      payload: response
    })
    setLoading(false); setShow(false); setAction('');
    dispatch(getNotifyUser(setLoading, user_id)); toast.success("Email notification updated successful.");
  }).catch((error) => {
    setLoading(false); setShow(false); setAction('');
    dispatch(getNotifyUser(setLoading, user_id)); toast.error(error.response.data.message);
  })
}
/*--------------------------------------------manage Claim--------------------------*/
export const addClaim = (obj, user_id, setLoading, setShow, setAction) => (dispatch) => {
  UserClient.post(`/user/create-invoice-claim`, obj).then((response) => {
    dispatch({
      type: ADD_INVOICE_CLAIM,
      payload: response
    })
    setLoading(false); setShow(false); setAction('');
    toast.success("Claim added successful.");
    setLoading(true);
    dispatch(getDataList(`/user/get-invoice-claim/${user_id}/Received/?page=1`,GET_INVOICE_CLAIM_LIST,setLoading))
  }).catch((error) => {
    setLoading(false); setShow(false); setAction('');
    toast.error(error.response.data.message);
  })
}

export const updateClaim = (obj, user_id, setLoading, setShow, setAction,update_id) => (dispatch) => {
  UserClient.post(`/user/update-invoice-claim/${update_id}`, obj).then((response) => {
    dispatch({
      type: EDIT_INVOICE_CLAIM,
      payload: response
    })
    setLoading(false); setShow(false); setAction('');
    toast.success("Claim Updated successful.");
    setLoading(true);
    dispatch(getDataList(`/user/get-invoice-claim/${user_id}/Received/?page=1`,GET_INVOICE_CLAIM_LIST,setLoading))
  }).catch((error) => {
    setLoading(false); setShow(false); setAction('');
    toast.error(error.response.data.message);
  })
}

export const updateClaimMessageUser = (id,obj, claim_id, setLoading, setShow) => (dispatch) => {
  UserClient.post(`/user/update-claim-message/${id}`, obj).then((response) => {
    setLoading(false);
    setShow(false); 
    dispatch(getDataList(`/user/get-claim-message/${claim_id}/?page=1`, GET_CLAIM_MESSAGE_LIST, setLoading)); toast.success('Note Updated successful.');
  }).catch((error) => {
    setTimeout(() => { setLoading(false); setShow(false); }, 1000);
    toast.error(error.response.data.message);
  })
}
export const deleteClaimFileUser = (id, claim_id, setLoading, setShow) => (dispatch) => {
  UserClient.get(`/user/delete-claim-file/${id}`).then((response) => {
    setLoading(false);
    dispatch(getDataList(`/user/get-claim-message/${claim_id}/?page=1`, GET_CLAIM_MESSAGE_LIST, setLoading)); toast.success('File Removed successful.');
  }).catch((error) => {
    setTimeout(() => { setLoading(false); setShow(false); }, 1000);
    toast.error(error.response.data.message);
  })
}
export const addFeedback = (obj, setLoading) => () => {
  UserClient.post(`/user/add-feedback`, obj).then((response) => {
    setLoading(false);
    // toast.success("Feedback added successful.");
  }).catch((error) => {
    setLoading(false);
    toast.error(error.response.data.message);
  })
}
export const getUserFeedback = (id, setLoading) => (dispatch) => {
  UserClient.get(`/user/get-user-feedback/${id}`).then((response) => {
    setLoading(false);
    dispatch({
      type: USER_FEEDBACK,
      payload: response.data.data
    })    
  }).catch((error) => {
    setLoading(false);
    toast.error(error.response.data.message);
  })
}

export const getdeleteCallFun = (url, setLoading) => () => {
  UserClient.get(url).then((res) => {   
    setLoading(false)
  }).catch((error) => {
    setLoading(false) 
    console.log(error)
  })
}

export const getdeleteFun = (id, setLoading) => () => {
  const formdata = new FormData();
  formdata.append('user_id',user?.id );
  UserClient.post(`/user/delete-feedback-msg/${id}`,formdata).then((res) => {   
    setLoading(false)
  }).catch((error) => {
    setLoading(false) 
    console.log(error)
  })
}

export const sendUserNofity= (data,setLoading) => () => {
  const formdata = new FormData();
  formdata.append('user_id', user?.id == 'undefined' ? data?.user_id: user?.id );
  formdata.append('receiver_id', data?.receiver_id ? data?.receiver_id : 0);
  formdata.append('role', user?.role );
  formdata.append('type', data?.type ? data?.type : '');
  formdata.append('title', data?.title ? data?.title : '');
  formdata.append('description', data?.description ? data?.description : '');
  formdata.append('url_id', data?.url_id ? data?.url_id : 0);
  UserClient.post(`/user/add-event-notification`, formdata).then(() => {    
    setLoading(false)
  }).catch((error) => {
    setLoading(false)
    toast.error(error.response.data.message); 
  })
}