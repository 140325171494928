export const ADMIN_DASHBOARD = "/admin/dashboard";
export const ADMIN_LOGIN = "/admin/login";
export const ADMIN_USER="/admin/clients";
export const ADMIN='/admin';
export const ADMIN_INVOICE="/admin/write-invoice-estimate";
export const ADMIN_COMPLETED_INVOICE="/admin/completed-jobs";
export const ADMIN_INVOICE_VIEW="/admin/write-invoice-estimate/:slug_id";
export const ADMIN_INVOICE_COMPLETED_VIEW="/admin/completed-jobs/:slug_id";
export const ADMIN_ORDERS="/admin/orders";
export const ADMIN_PAYMENT="/admin/payment";
export const ADMIN_JOB_TYPE="/admin/job-type";
export const ADMIN_STATUS="/admin/status";
export const ADMIN_TEAM="/admin/team";
export const ADMIN_NOTIFICATION="/admin/notification";
export const ADMIN_CLAIM="/admin/claim";
export const ADMIN_VIEW_ORDERS="/admin/view/orders/:slug_id";

export const ADMIN_CLAIM_DASHBOARD = "/admin/claim/dashboard";
export const ADMIN_CLAIM_ACTIVE_JOB = "/admin/claim/active-jobs";
export const ADMIN_CLAIM_ACTIVE_JOB_VIEW="/admin/claim/active-jobs/:slug_id";
export const ADMIN_CLAIM_COMPLETED_JOB = "/admin/claim/completed-jobs";
export const ADMIN_CLAIM_COMPLETED_JOB_VIEW="/admin/claim/completed-jobs/:slug_id";
export const ADMIN_CLAIM_CLIENT = "/admin/claim/clients";
export const ADMIN_CLAIM_USER = "/admin/claim/user";
export const ADMIN_CLAIM_REPORT = "/admin/claim/reports";
export const ADMIN_FEEDBACK = "/admin/feedback";

